import AvatarCardItem from '../avatarCard/components/AvatarCardItem';
import AvatarCardHeadshot from '../avatarCard/components/AvatarHeadshot';
import AvatarCardCaption from '../avatarCard/components/AvatarCaption';
import AvatarCardMenu from '../avatarCard/components/AvatarMenu';
import AvatarCardMenuItem from '../avatarCard/components/AvatarMenuItem';
import AvatarCardButtonGroup from '../avatarCard/components/AvatarButtonGroup';
import AvatarCardContent from '../avatarCard/components/AvatarContent';
import AvatarCardList from '../avatarCard/components/AvatarCardList';
import Button from '../button/components/Button';
import IconButton from '../button/components/IconButton';
import Dropdown from '../dropdown/components/RobloxDropdown';
import FileUpload from '../fileUpload/components/FileUpload';
import FilterSelect from '../filterSelect/components/FilterSelect';
import Form from '../form';
import Image from '../image/containers/Image';
import Link from '../link/components/Link';
import Loading from '../loaders/components/Loading';
import Modal from '../modal/components/RobloxModal';
import ModalHeader from '../modal/components/RobloxModalHeader';
import ModalBody from '../modal/components/RobloxModalBody';
import ModalFooter from '../modal/components/RobloxModalFooter';
import NativeDropdown from '../dropdown/components/NativeDropdown';
import SimpleModal from '../modal/components/RobloxSimpleModal';
import Pagination from '../pagination/components/Pagination';
import Popover from '../popover/components/Popover';
import SystemFeedback from '../systemFeedback/components/SystemFeedbackContainer';
import Tooltip from '../tooltip/components/Tooltip';
import Tabs from '../tabs/components/Tabs';
import Toast from '../toast/component/Toast';
import Toggle from '../toggle/components/Toggle';
import Section from '../section/components/Section';
import SimpleTabs from '../tabs/components/SimpleTabs';
import ScrollBar from '../scrollBar/scrollBar';
import createModal from '../modal/utils/createModal';
import createSystemFeedback from '../systemFeedback/utils/createSystemFeedback';

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

window.ReactStyleGuide = {
  // Components
  AvatarCardItem: {
    Default: AvatarCardItem,
    Headshot: AvatarCardHeadshot,
    Content: AvatarCardContent,
    ButtonGroup: AvatarCardButtonGroup,
    Caption: AvatarCardCaption,
    Menu: AvatarCardMenu,
    MenuItem: AvatarCardMenuItem
  },
  AvatarCardList,
  Button,
  Dropdown,
  FileUpload,
  FilterSelect,
  Form,
  IconButton,
  Image,
  Link,
  Loading,
  Modal,
  NativeDropdown,
  Pagination,
  Popover,
  Section,
  SimpleModal,
  SimpleTabs,
  ScrollBar,
  SystemFeedback,
  Tabs,
  Tooltip,
  Toast,
  Toggle,
  // Utilities
  createSystemFeedback,
  createModal
};
